<script setup lang="ts">
import pageQuery from '@/apollo/queries/FrontPage';
import type { FrontPage, NodeFrontPage } from '@/apollo/schema/FrontPage';
import { useSeoStore } from '@/store/seo';

import RenderContent from '@/components/RenderContent';

const config = useRuntimeConfig();

const seoStore = useSeoStore();
const { $sentry } = useNuxtApp();

const {
  setGoogleAnalyticsToStore,
  sendGoogleAnalyticsPageViewFromStore
} = useAppAnalytics();

const {
  setBreadcrumbs
} = useBreadcrumb();

const page = ref<FrontPage|null>(null);

const { data, error } = await useAsyncData('homepage', async () => {
  try {
    const [stylesheet, page] = await Promise.all([
      $fetch<string>(`${config.public.wpEndpoint}/wp-json/wp/v2/style-assets?slug=/`),
      await useAsyncQuery<NodeFrontPage>(pageQuery)
    ]);

    const { isFrontPage, generalSettings } = page.data.value!;

    return {
      isFrontPage,
      generalSettings,
      stylesheet
    };
  } catch (e) {
    $sentry.captureException(e as Error);
    throw e;
  }
});

if (error.value) {
  // @ts-ignore
  throw createError({ statusCode: error.value?.statusCode, statusMessage: error.value?.message });
}

if (!data.value?.isFrontPage) {
  throw createError({ statusCode: 404, statusMessage: 'Page not found' });
}

page.value = data.value!.isFrontPage;

const { seo, metaGoogleAnalytics } = data.value!.isFrontPage;

const seoTitle = seo.pageTitle ? seo.pageTitle : data.value!.isFrontPage.title;
const seoDescription = seo.pageDescription ? seo.pageDescription : data.value!.generalSettings.description;

seoStore.title = seoTitle;
seoStore.description = seoDescription;

setGoogleAnalyticsToStore(metaGoogleAnalytics);
setBreadcrumbs();

onMounted(() => {
  sendGoogleAnalyticsPageViewFromStore();
});

useHead(() => ({
  style: [
    {
      innerHTML: data.value!.stylesheet
    }
  ]
}));

useHead(() => ({
  script: [
    {
      src: `${config.public.wpEndpoint}/wp-json/wp/v2/script-assets?slug=/`
    }
  ]
}), {
  mode: 'server'
});
</script>

<template>
  <RenderContent
    v-if="page"
    :html="page.renderContent"
  />
</template>

<style lang='scss'>
@import '@burinka/visual-library/dist/flModules.css';
</style>
